import * as React from "react";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import { FocusAreaIcon } from "../../components/svg";
import DigitalHealthEcosystem from "../../images/DigitalHealthEcosystem.jpg";

const HealthcarePage = () => (
  <Layout>
    <Helmet title="Digital Health - Levercode" />
    {/* Sub Header image */}
    <div className="_healthcare_hero mt-32">
      <div className="flex">
        <FocusAreaIcon icon="healthcare" className="mx-auto w-auto h-32 sm:h-80 -mt-20 text-yellow-800 fill-current" />
      </div>

      {/* Sub Header intro text */}
      <div className="mx-auto py-12 sm:py-24 text-white">
        <h1 className="text-4xl sm:text-7xl leading-normal font-bold py-10 text-center">
          <span className="whitespace-nowrap">
            <span className="text-yellow-800 text-5xl sm:text-8xl">[</span> Digital Health <span className="text-yellow-800 text-5xl sm:text-8xl">]</span>
          </span>
        </h1>
        <p className="sm:px-2/12 text-xl sm:text-3xl leading-relaxed text-center pb-40">Levercode’s Approach to Healthcare</p>
      </div>
    </div>
    {/* Content */}
    <div className="container max-w-screen-xl py-32 text-2xl">
      {/*<h2 className="text-5xl text-blue-800 ">Digital Health by Levercode</h2>*/}

      <p>
        Information about our health is one of the most important things to us. The same goes for getting proper hospital treatment or healthcare advice. However, in the case of serious accidents or medical emergencies, it goes far beyond just peace of
        mind - accurate information regarding person’s blood type, chronic health issues, and medication history literally become a matter of life and death. COVID-19 and other communicable diseases have only compounded the{" "}
        <strong>importance of accessing fast and reliable information</strong> in this area. 
      </p>

      <p className="mt-16">
        Although getting actual health information swiftly is important, it’s also critical that such <strong>information is kept private and shared only with parties that really need it or are authorized to know</strong>. Data systems that contain and
        process health data have to be reliable and provide a high level of protection for sensitive information. The system should also be able to record all data processing such as entry, alteration and viewing events.
      </p>

      <p className="mt-16 font-bold border-l-4 border-blue-800 pl-8 text-black">One way to secure the long term reliability and sustainability of the system is to utilize Distributed Ledger Technologies and Blockchain.</p>

      <p className="mt-16">
        Modern e-Health systems should bring together all relevant stakeholders such as patients, doctors, hospitals, public and private insurers, governments, and other service providers like pharmacies, payment processors etc. Since every stakeholder has
        specific role in this ecosystem, their rights to get and obligations to submit information, are also different.
      </p>

      <p className="mt-16">
        <strong>Digital Identity - the critical component of the system</strong> - is the basis to provide access to the system and allow/prohibit all activities within it. In terms of providing access to sensitive personal and health data, following the
        „Patient is King“ principle is a must. The patient should decide if his/her health data is shared only with family doctor, or also made available to other stakeholders. 
      </p>

      <p className="mt-16">
        <img src={DigitalHealthEcosystem} alt="Digital Health Ecosystem" />
      </p>

      <p className="mt-16">
        Levercode brings together the experts that have long time experience in e-Health domain and all foundational components (such as Digital Identity, Data Exchange Layer, and Data Registries){" "}
        <strong>that are required to build up modern e-Health ecosystems</strong>. Technologies and standards evolve and our experts have kept up the pace.
      </p>

      <p className="mt-16">
        Speaking of registries, e-Health ecosystem is rich in them. Natural part of the fully functional system are registries of the countries population, certified doctors, certified and imported drugs and the Data Exchange Layer (one such solution is
        X-Road), is the glue that binds the registries together and makes it possible to pull together information that is needed for provision of specific health related service.
      </p>

      <p className="mt-16">
        Levercode designs and models the Foundation and e-Health systems from scratch. However, if a country has the systems already in place but they’re not operating as expected,{" "}
        <strong>our Research Lab is capable of auditing and evaluating existing systems' weaknesses, data and process risk</strong>. We decompose the systems and put our primary focus on the “Red Lines“ – the processes with the highest risk. Naturally, we
        can provide recommendations for remedy or step in to redesign the systems based on industry standards, clients’ needs, and preferences.
      </p>

      <p className="mt-16">Please contact us to learn more about Levercode’s approach to building Digital Society and the ways we can build value for your country or organization. </p>
    </div>
  </Layout>
);

export default HealthcarePage;
